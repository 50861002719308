.modal-container {
  position:fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.75);

  &.hidden {
    display: none;
  }

  .modal {
    border-radius: 2rem;
    width: 30%;
    margin-left: 35%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-bottom: 1rem;
    background-color: white;

    @media screen and (max-width: 720px) {
      padding-left: 1rem;
      padding-right: 1rem;
      width: 80%;
      margin-left: 5%;
      margin-right: 5%;
    }

  }
}
