@import 'shared.scss';

body {
  background-color: darken($main-bg-color, 2%);
  font-family: monospace;
}

header {
  border-bottom: solid 4px $hero-color;
}

header {
  h1 {
    margin-bottom: 0;
  }
}

.app {
   margin-top: 2rem;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.timer {
  font-size: 7rem;
  font-weight: bold;
  font-family: monospace;
}

button.toggle {
  font-family: monospace;
  font-size: 2rem;
  padding: 0.75rem 4rem;
}

.pomodoro-type {
  background-color: darken($main-bg-color, 15);
  padding: 0.25rem 0.75rem;
  border-radius: 0.75rem;
}

/* Override dark theme with light theme https://github.com/kevquirk/simple.css/blob/main/simple.css */
@media (prefers-color-scheme: dark) {
  :root {
  --bg: #fff;
  --accent-bg: #f5f7ff;
  --text: #212121;
  --text-light: #585858;
  --border: #d8dae1;
  --accent: #0d47a1;
  --code: #d81b60;
  --preformatted: #444;
  --marked: #ffdd33;
  --disabled: #efefef;
  }
}

button {
  background-color: $hero-color;
}

header {
  display: flex;
  justify-content: space-between;
}
nav {
  margin-top: 1rem
}

.hidden {
  visibility: hidden
}

.controls {
  display: flex;
  button.toggle {
    margin-left: 2rem;
  }

  button.skip {
    font-size: 2rem;
    font-weight: bold;
    font-family: monospace;
    margin-left: 2rem;
  }
}
